import { render, staticRenderFns } from "./ModalCommentList.vue?vue&type=template&id=2a774224&scoped=true&"
import script from "./ModalCommentList.vue?vue&type=script&lang=js&"
export * from "./ModalCommentList.vue?vue&type=script&lang=js&"
import style0 from "./ModalCommentList.vue?vue&type=style&index=0&id=2a774224&lang=scss&scoped=true&"
import style1 from "./ModalCommentList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a774224",
  null
  
)

export default component.exports