<template>
  <div>
    <b-modal
      id="modal-comment-list"
      title="Xem tất cả nhận xét"
      centered
      hide-footer
      size="xl"
      @hidden="resetModal"
      @show="onShow"
    >
      <b-overlay :show="loading">
        <b-row class="align-items-end">
          <b-col cols="6">
            <label for="">Ngày nhận xét</label>
            <b-row>
              <b-col cols="6">
                <date-picker
                  placeholder="Từ ngày"
                  name="startDate"
                  input-class="form-control"
                  type="datetime"
                  :format="dateFomartion"
                  value-type="format"
                  :disabled-date="disabledBeforeFromDate"
                  v-model="filter.startDate"
                  @close="loadData"
                  @clear="loadData"
                />
              </b-col>
              <b-col cols="6">
                <date-picker
                  placeholder="Đến ngày"
                  name="endDate"
                  input-class="form-control"
                  type="datetime"
                  :format="dateFomartion"
                  value-type="format"
                  :disabled-date="disabledBeforeToDate"
                  v-model="filter.endDate"
                  @close="loadData"
                  @clear="loadData"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <basic-input
              label="Người nhận xét"
              placeholder="Nhập tên người nhận xét"
              class="form__viewer"
              :debounce="1000"
              :value.sync="filter.reviewName"
              @v-modal:update="searchDebounce"
            />
          </b-col>
          <b-col class="text-right">
            <b-button
              v-if="
                isPartWritePermission ||
                isWriteAllPermission ||
                isWritePermission
              "
              class="btn btn-success"
              type="button"
              @click="openModalComment"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
              </span>
              Thêm mới
            </b-button>
          </b-col>
        </b-row>
        <div class="py-6">
          <template-table
            :data="comments"
            :column="headers"
            :selectAction="false"
            :tableAction="false"
            :searchAction="false"
            :paging="paging"
          >
            <template v-slot:body="{ item }">
              <td>{{ convertTimestampToDate(item.updateDateTime) }}</td>
              <td>{{ item.creator }}</td>
              <td>
                {{ getRatingType(item.type) }}
              </td>
              <td>
                {{
                  item.coachDate > 0
                    ? convertTimestampToDate(item.coachDate)
                    : ''
                }}
              </td>
              <td class="cell-overflow">
                <div
                  v-if="item.comment || item.finalResult"
                  class="text-overflow"
                >
                  <span v-html="item.comment || item.finalResult"></span>
                </div>
                <div v-else class="text-dark-50">+ Thêm nhận xét...</div>
              </td>
              <td>
                <div class="d-flex justify-content-around align-items-center">
                  <template
                    v-if="
                      isPartWritePermission ||
                      isWriteAllPermission ||
                      isWritePermission
                    "
                  >
                    <template v-if="item.comment || item.finalResult">
                      <b-button
                        class="btn btn-edit"
                        size="sm"
                        type="button"
                        @click="openModalEditComment(item)"
                      >
                        <span class="svg-icon mr-0">
                          <inline-svg
                            src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                          />
                        </span>
                      </b-button>
                      <b-button
                        class="btn btn-view ml-2"
                        size="sm"
                        type="button"
                        @click="openModalViewComment(item)"
                      >
                        <span class="svg-icon mr-0">
                          <inline-svg
                            src="/media/svg/icons/Neolex/Basic/eye.svg"
                          />
                        </span>
                      </b-button>
                    </template>
                    <b-button
                      v-else
                      class="btn btn-edit"
                      size="sm"
                      type="button"
                      @click="openModalComment"
                    >
                      <span class="svg-icon mr-0">
                        <inline-svg
                          src="/media/svg/icons/Neolex/Basic/plus.svg"
                        />
                      </span>
                    </b-button>
                  </template>
                </div>
              </td>
            </template>
          </template-table>
        </div>
      </b-overlay>
    </b-modal>
    <ModalComment
      :typeForm="modalType"
      :commentId="commentId"
      :isOpenFromCommentList="isOpenFromCommentList"
      @reset="resetModalComment"
    />
  </div>
</template>
<script>
import { CALENDAR_TYPES, MODAL_TYPE } from '../../../../core/plugins/constants';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'ModalCommentList',
  components: {
    'date-picker': DatePicker,
    ModalComment: () => import('./ModalComment.vue'),
  },
  data() {
    return {
      loading: false,
      comments: [],
      headers: [
        {
          label: 'Ngày',
          class: 'text-left',
          key: 'date',
          style: {
            width: '10%',
          },
        },
        {
          label: 'Người nhận xét',
          class: 'text-left',
          key: 'reviewer',
          style: {
            width: '15%',
          },
        },
        {
          label: 'Loại đánh giá',
          class: 'text-left',
          key: 'ratingType',
          style: {
            width: '15%',
          },
        },
        {
          label: 'Buổi coach',
          class: 'text-left',
          key: 'coachSection',
          style: {
            width: '10%',
          },
        },
        {
          label: 'Nhận xét cho khách hàng',
          class: 'text-left',
          key: 'commentForCustomer',
        },
        {
          label: '',
          class: 'text-left',
          key: 'actions',
          style: {
            width: '5%',
          },
        },
      ],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      filter: {
        reviewName: null,
        startDate: null,
        endDate: null,
      },
      dateFomartion: 'DD/MM/YYYY HH:mm',
      modalType: MODAL_TYPE.CREATE,
      isOpenFromCommentList: false,
      commentId: null,
    };
  },
  computed: {
    patientId() {
      return this.$route.params.id;
    },
    searchParams() {
      return {
        patientId: this.patientId,
        page: this.paging.page,
        size: this.paging.pageSize,
        reviewName: this.filter.reviewName,
        startUpdateDatetime:
          this.filter.startDate &&
          this.convertDateToTimestamp(
            this.filter.startDate,
            'DD/MM/YYYY HH:mm',
          ),
        endUpdateDatetime:
          this.filter.endDate &&
          this.convertDateToTimestamp(this.filter.endDate, 'DD/MM/YYYY HH:mm'),
      };
    },
    isPartWritePermission() {
      return this.$route.meta.isPartWritePermission;
    },
    isWriteAllPermission() {
      return this.$route.meta.isWriteAllPermission;
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.loadData();
    },
  },
  methods: {
    resetModal() {
      this.comments = [];
      this.paging = {
        page: 1,
        pageSize: 10,
        total: 0,
      };
      this.filter = {
        reviewName: null,
        startDate: null,
        endDate: null,
      };
      this.$emit('reset');
    },
    onShow() {
      this.loadData();
    },
    async loadData() {
      try {
        this.$store.commit('context/setLoading', true);
        const { meta, data, error } = await this.$api.get(
          '/UserDashboard/Calendar-Training-Comment',
          {
            params: this.searchParams,
          },
        );
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }

        this.comments = data.items;
        this.paging.total = data.total;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },
    getRatingType(type) {
      switch (type) {
        case CALENDAR_TYPES.OUTPUT_RATING:
          return 'Đánh giá đầu ra';
        case CALENDAR_TYPES.INPUT_RATING:
          return 'Đánh giá đầu vào';
        case CALENDAR_TYPES.COACH_11:
          return 'Coach 1-1';
        case CALENDAR_TYPES.COACH_1N:
          return 'Coach 1-n';

        default:
          return 'Bác sĩ đánh giá';
      }
    },
    disabledBeforeToDate(date) {
      if (this.filter.startDate) {
        const newDate = this.convertToISODate(this.filter.startDate);
        const deadline = new Date(newDate);
        deadline.setHours(0, 0, 0, 0);

        return date < deadline;
      }
    },
    disabledBeforeFromDate(date) {
      if (this.filter.endDate) {
        const newDate = this.convertToISODate(this.filter.endDate);
        const deadline = new Date(newDate);
        deadline.setHours(0, 0, 0, 0);

        return date > deadline;
      }
    },
    searchDebounce(value) {
      this.filter.reviewName = value;
      this.loadData();
    },
    openModalComment() {
      this.isOpenFromCommentList = true;
      this.$bvModal.hide('modal-comment-list');
      this.$bvModal.show('modal-comment');
    },
    openModalEditComment(item) {
      this.commentId = item.id;
      this.modalType = MODAL_TYPE.EDIT;
      this.openModalComment();
    },
    openModalViewComment(item) {
      this.commentId = item.id;
      this.modalType = MODAL_TYPE.DETAIL;
      this.openModalComment();
    },
    resetModalComment() {
      this.modalType = MODAL_TYPE.CREATE;
      this.commentId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-edit::v-deep,
.btn-view::v-deep {
  background: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

<style lang="scss">
.form__viewer {
  .form-group {
    margin-bottom: 0;
  }
}

p {
  margin-bottom: 0;
}
</style>
